<template>
  <div class="card card-custom px-3">
    <div class="card-custom-header" v-if="title">
      <div class="d-flex justify-content-between">
        <strong class="py-3 text-muted d-block">{{ title }}</strong>
        <slot name="card-header"></slot>
      </div>

      <hr class="divider" />
    </div>

    <slot name="card-body"></slot>
    <slot name="card-footer"></slot>
  </div>
</template>

<script>
export default {  
  props: {
    title: String,
  },
};
</script>

<style lang="scss" scoped>
.card-custom-header {
  font-size: 15px;
}
</style>